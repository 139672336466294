import { graphql } from "gatsby"
import React from "react"
import { copyright, download, homepagelink } from "./copyright.module.css"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

/* Components */
import Seo from "../components/seo/seo"
import Layout from "../components/layout"
import Footer from "../components/footer/footer"

/* Components */
import StickyNav from "../components/sticky-nav/sticky-nav"

const Copyright = ({ data }) => {
  const navigationDesks = data.navigationDesks.nodes;
  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href="https://www.mkbservicedesk.nl/copyright/" />
      </Helmet>
      <header className={"header"}>
        <StickyNav desks={navigationDesks} />
      </header>
      <main className="main">
        <div className="container">
          <Seo title="Copyright MKB Servicedesk" />
          <div className={copyright}>
            <h1>Copyright Notice</h1>
            <p>
              Alle (intellectuele) eigendomsrechten met betrekking tot MKB
              Servicedesk en het logo van mkbservicedesk.nl, waaronder in ieder
              geval worden begrepen de merkrechten, berusten uitsluitend bij MKB
              Servicedesk en haar groepsmaatschappijen (hierna tezamen aangeduid
              als: “MKB Servicedesk”). MKB Servicedesk behoudt zich deze rechten
              uitdrukkelijk voor.
            </p>
            <p>
              De (auteurs-)rechten met betrekking tot het format en de inhoud
              van deze website (de daarin opgenomen gegevens, afbeeldingen,
              geluiden, teksten en andere informatie) berusten uitsluitend bij
              MKB Servicedesk en/of haar licentiegevers. De inhoud van deze
              website mag alleen worden gebruikt voor persoonlijk gebruik.
              Zonder voorafgaande schriftelijke toestemming van MKB Servicedesk
              is het onder meer niet toegestaan de inhoud van deze website, of
              enig onderdeel daarvan, door te sturen, te verspreiden of tegen
              vergoeding beschikbaar te stellen aan derden, behalve voor zover
              strikt noodzakelijk is voor het gebruiken van de website.
            </p>
            <p>
              Indien en voor zover de inhoud van deze website bestaat uit
              bijdragen van gebruikers, rusten de rechten daarop bij deze
              gebruikers (tenzij in de gebruikersvoorwaarden van deze website
              afstand is gedaan van deze rechten en/of deze rechten zijn
              overgedragen aan MKB Servicedesk).
            </p>
            <p>
              MKB Servicedesk heeft het recht, doch niet de plicht, om de
              toegang tot informatie te onthouden dan wel om informatie van deze
              website te verwijderen indien er, naar het uitsluitend oordeel van
              MKB Servicedesk, sprake is van (mogelijke) inbreuk op rechten van
              derden.
            </p>
            <p>
              MKB Servicedesk heeft tevens het recht om - tenzij anders
              overeengekomen met de auteur - ingezonden materiaal in te korten
              en/of aan te passen. Dit geldt voor tekst-, muziek- en
              beeldmateriaal.
            </p>
            <Link
              className={download}
              to="https://www.mkbservicedesk.nl/documenten/MKBSD-Copyright-18112020.pdf"
            >
              Gebruiksvoorwaarden Downloaden
            </Link>
            <p className={homepagelink}>
              Klik{" "}
              <a href="/" title="MKB Servicedesk Homepage">
                hier
              </a>{" "}
              om naar de homepage te gaan.
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </Layout>
  )
}

export default Copyright

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    navigationDesks: allContentfulDesk(sort: { fields: sortNavigation }) {
      nodes {
        id
        name
        slug2
        sortNavigation
        ctaNavigation {
          image {
            title
            gatsbyImageData(layout: CONSTRAINED, width: 1180)
          }
          title
          slug
          categories {
            slug2
            desk {
              slug2
            }
          }
        }
        deskcategory {
          id
          name
          slug2
          desk {
            id
            name
            slug2
          }
          article {
            id
            title
            slug2
            updateDate
            categories {
              slug2
              desk {
                slug2
              }
            }
          }
        }
      }
    }
  }
`
